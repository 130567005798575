import {AppApi} from 'store/customer/api';
import {
    Product,
    ProductForm,
    Validation,
    Structure,
    Cabinet,
} from 'components/customer/Product/entity';
import {mapHiddenMaterials} from 'components/customer/Product/helpers/mapHiddenMaterials';
import {CabinetFormField} from '../previewTypes/CabinetFormFieldDefaults';

type PreviewPayloadProps = {
    cabinet: Cabinet;
    cabinet_form_field_defaults: object[];
    cabinet_form_fields: CabinetFormField[];
    edge_field_labels: object[];
    job_cabinet: Product;
    majorDimensions?: string[][];
    structure: Structure[];
    success: number;
    validation?: Validation[];
    uses_edge_finishes: boolean;
};

type PreviewResultProps = {
    form_fields: {
        cabinet_form_field_defaults: object[];
        cabinet_form_fields: CabinetFormField[];
        edge_field_labels: object[];
        uses_edge_finishes: boolean;
    };
    product_config: ProductForm;
    cabinet: Cabinet;
    job_cabinet: Product;
};

// The following contains the endpoints for 3d preview
const PreviewApi = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getCabinetPreview: builder.query<PreviewResultProps, {slug: string}>({
            query: ({slug}) => `/jobcabinet/${slug}/preview`,
            transformResponse: (response: PreviewPayloadProps) => {
                const result: PreviewResultProps = {
                    cabinet: response.cabinet,
                    job_cabinet: mapHiddenMaterials(response.job_cabinet),
                    form_fields: {
                        cabinet_form_field_defaults:
                            response.cabinet_form_field_defaults,
                        cabinet_form_fields: response.cabinet_form_fields,
                        edge_field_labels: response.edge_field_labels,
                        uses_edge_finishes: response.uses_edge_finishes,
                    },
                    product_config: {
                        majorDimensions: response.majorDimensions,
                        validation: response.validation,
                        structure: response.structure,
                    },
                };
                return result;
            },
        }),
    }),
});

export const {useGetCabinetPreviewQuery, useLazyGetCabinetPreviewQuery} =
    PreviewApi;
